<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getListUserHistory"
  :isLoading="isLoading"
  showExpand
  componentInfo="UserHistoryInfo"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'UserHistory',
  data () {
    return {
      headers: [
        { value: 'datetime', text: this.$t('dateModified'), align: 'center' },
        { value: 'sailor_key', text: this.$t('sailorId'), align: 'center' },
        { value: 'full_user_name', text: this.$t('fullName'), align: 'center', sortable: false },
        { value: 'model', text: this.$t('typeDoc'), align: 'center' },
        { value: 'event', align: 'end', text: this.$t('actions'), sortable: false },
        { value: 'data-table-expand', align: 'start', sortable: false }

      ],
      actions: [
        {
          id: 1,
          to: (item) => {
            return { name: 'sailor', params: { id: item.sailor } }
          },
          checkAccess: (item) => item.sailor,
          color: 'blue',
          name: 'mdi-account-circle-outline',
          tooltip: 'tooltip.goToSailor',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.user.userHistory,
      isLoading: state => state.user.isLoading
    })
  },
  methods: {
    ...mapActions(['getListUserHistory'])
  }
}
</script>
